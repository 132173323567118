.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
}
.icon {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 48px;
  background: #1c1c1d;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
}
.name {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin: 0;
}
.amount {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  opacity: 0.6;
}
.iconImage {
  width: 48px;
}
