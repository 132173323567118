@import "../../../styles/variables.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-self: stretch;
  padding-bottom: 32px;
}
.inputWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
  row-gap: 24px;
  @media (max-width: $vp-767) {
    display: flex;
    flex-direction: column;
  }
}
.item {
  position: relative;
}
.buttonWrapper {
  display: flex;
  justify-content: flex-end;
}
.button {
  min-width: 183px;
}
.error {
  color: red;
}
