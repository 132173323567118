@import "../../../styles/variables.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  max-width: 891px;
  margin-bottom: 32px;
}
.title {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
}
.contentWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 32px;
  row-gap: 24px;
  @media (max-width: $vp-767) {
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    margin-bottom: 32px;
  }
}
