@import "../../../styles/variables.scss";
.component {
  display: flex;
  width: 100%;
  height: 48px;
  padding: 19px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #1f1d2b;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }

  @media (max-width: 390px) {
    padding: 19px 12px;
    gap: 6px;
  }

  .text {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    @media (max-width: 390px) {
      font-size: 13px;
      font-weight: 400;
    }
  }

  &:disabled {
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.05);
    background: rgba(31, 29, 43, 0.3);
    cursor: unset;

    .text {
      color: rgba(255, 255, 255, 0.7);
      font-size: 14px;
      font-weight: 500;
      @media (max-width: 390px) {
        font-size: 13px;
        font-weight: 400;
      }
    }
    &:hover {
      outline: none;
      opacity: 1;
    }
  }
}

.componentProcessing {
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: rgba(31, 29, 43, 0.3);

  .text {
    opacity: 0.7;
  }

  &:hover {
    opacity: 1;
  }
}
