.component {
  display: flex;
  padding: 8px 10px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid #4528e3;
  background: linear-gradient(
    94deg,
    rgba(69, 40, 227, 0.1) 0%,
    rgba(165, 79, 218, 0.1) 100%
  );
}
.text {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}
