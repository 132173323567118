.component {
  background: linear-gradient(100deg, #16161a, #222225, #16161a);
  background-size: 240% 240%;
  animation: gradient-animation 3s ease infinite;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  max-width: 100%;
}
.placeHolderBg {
  background: linear-gradient(100deg, #16161a, #222225, #16161a);
  background-size: 240% 240%;
  animation: gradient-animation 3s ease infinite;
  flex: 1;
  aspect-ratio: 1 / 1;
  height: 100%;
  overflow: hidden;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
