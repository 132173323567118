@import "../../../styles/variables.scss";
.wrapper {
  display: grid;
  gap: 12px;
  width: 100%;
  padding-bottom: 40px;
}

.title {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}

.subtitle {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-weight: 400;
}

.inputsWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 32px;
  margin-top: 32px;

  > * {
    flex: 1;
    min-width: 200px;
  }
}

.domainWrapper {
  position: relative;

  & > input {
    background-color: red Im !important;
  }

  input:before {
    content: ".stxs";
    position: absolute;
    top: 17px;
    right: 24px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    font-weight: 400;
  }
}

.input input {
  width: 88%;

  @media (max-width: $vp-375) {
    width: 82%;
  }
}

.input:before {
  content: ".stxs";
  position: absolute;
  top: 17px;
  right: 24px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  font-weight: 400;
}

.button {
  width: 183px;
  margin: 28px 0 0 auto;
  @media (max-width: $vp-375) {
    width: 100%;
    margin: 28px 0 0;
  }
}

.warning {
  text-align: center;
  color: #ec2323;
  margin-top: 28px;
}

.mt16 {
  margin-top: 16px;
}
