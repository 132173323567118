.component {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.1);
}
.completed {
  background: #6afa25;
}
.buyingInProgress {
  background: #facc25;
}
.cancelled {
  background: #fa3f25;
}
.cancellingProgress {
  background: #facc25;
}
.created {
  background: transparent;
}
