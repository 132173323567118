.component {
  display: flex;
  align-items: center;
  gap: 8px;
  opacity: 0.7;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}
.checkedWrapper {
  opacity: 1;
}
.customCheckbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.customCheckbox + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
}

.customCheckbox + label::before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1px solid #4528e3;
  border-radius: 4px;
  margin-right: 8px;
}

.customCheckbox:checked + label::before {
  outline: 1px solid transparent;
  border: 1px solid transparent;
  overflow: hidden;
  background: url("checked.svg") no-repeat 0 0;
  background-size: cover;
}

.label {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
}
