@import "../../../../styles/variables.scss";

.component {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: $vp-767) {
    margin-top: 32px;
    margin-bottom: 60px;
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
.alertTitle {
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  margin: 0;
}
.createOrderButton {
  margin-top: 8px;
  width: 100%;
  max-width: 200px;
}
.icon {
  height: 40px;
}
