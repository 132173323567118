.component {
  border-radius: 16px;
  background: linear-gradient(94deg, #4528e3 0%, #a54fda 100%);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-left: 1px solid #6a4ef4;
  border-right: 1px solid #bd67f6;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 24px;
  outline: none;
  transition: all ease 0.3s;
  cursor: pointer;

  &:hover {
    background: linear-gradient(94deg, #5638f2 0%, #b956f5 100%);
  }

  &:disabled {
    opacity: 0.5;
    cursor: unset;
  }
}

.text {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}
