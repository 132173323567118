@import "../../styles/variables.scss";

.component {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (max-width: $vp-768) {
    width: 100%;
  }
}
.rowDropdowns {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.selectedWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: #101013;
  padding: 18px 24px;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  width: 100%;
}
.caption {
  display: flex;
  align-items: center;
  gap: 8px;
}
.title {
  color: #fff;
}
.menuBg {
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.menuItems {
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #1f1d2b;
  position: absolute;
  z-index: 2;
  top: 55px;
  right: 0;
  width: 99%;
  overflow: hidden;
  padding: 8px 0;
  max-height: 200px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: #1f1d2b;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.2);
  }
}
.menuItem {
  display: flex;
  padding: 15px 16px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }
}
.arrow {
  transition: all 0.5s ease;
  transform: rotate(-180deg);
}
.arrowOpened {
  transform: rotate(0deg);
}
.placeholder {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
}
.floorPrice {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  margin-top: 8px;
}
.floorPriceLabel {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  & > span {
    color: #fff;
  }
}
.titleEmpty {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
}

.menuItemEmpty {
  display: flex;
  padding: 10px 16px;
  align-items: center;
  justify-content: space-between;
}
