.component {
  position: relative;
  width: 100%;
  display: grid;
  gap: 16px;
}

.inputWrapper {
  margin-bottom: 8px;
}

.input input {
  width: 100%;
}

.error {
  position: absolute;
  padding: 0 8px 0 0;
  right: 0;
  // bottom: -12px;
  color: #ff5b5b;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.clearAddress {
  position: absolute;
  right: 12px;
  top: 18px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  transform: scale(1);
  transition: all ease 0.3s;

  &:hover {
    transform: scale(1.5);
  }
}

.info {
  position: absolute;
  right: 12px;
  top: 2px;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  line-height: 56px;
  opacity: 0.5;
}
