@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
.container {
}
.root {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 430px;
  z-index: 2;
}
.component {
  position: relative;
  background: #292a2f;
  min-height: 50px;
  overflow: hidden;
  border-radius: 20px;
  animation: fadeIn 0.2s ease;
}
.close {
  background: #16161a;
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.wrapper {
  justify-content: flex-start;
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  border-radius: 16px;
  background: #16161a;
}
.wrapperTxs {
  justify-content: flex-start;
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  border-radius: 16px;
  background: #16161a;
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.title {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.listTxs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  align-self: stretch;
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: #16161a;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.2);
  }
}
.txWrap {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.txTitle {
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
}
.txStatus {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #fff;
  font-size: 12px;
  line-height: normal;
  margin-right: 4px;
}
.inputsWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.item {
  position: relative;
  width: 100%;
}
.button {
  width: 100%;
}
.simpleButton {
  width: 100%;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #1f1d2b;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  border: none;
  outline: 2px solid rgba(255, 255, 255, 0.1);
  transition: all ease 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
    background: #1f1d2b;
    // outline: 1.5px solid rgba(255, 255, 255, 0.3);
  }
}
.menuBg {
  position: fixed;
  z-index: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
}

.hr {
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
}
.dataRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
}
.dataRowGap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  gap: 14px;
}
.dataTitle {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.dataValue {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
}
.dataNote {
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #ff9029;
  background: rgba(255, 255, 255, 0.05);
}
.noteText {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  line-height: normal;
  text-align: center;
  & > span {
    color: #fff;
  }
}
.warningWrapper {
  display: flex;
  border-radius: 8px;
  border: 1px solid #ff9029;
  background: rgba(255, 255, 255, 0.05);
}
.warningContent {
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin: auto;
  padding: 8px;
  & > span {
    color: #fff;
  }
}
