@import "../../styles/variables.scss";
.root {
  display: flex;
  max-width: 314px;
  width: 100%;
}
.component {
  display: flex;
  width: 100%;
  min-height: 282px;
  padding: 12px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 16px;
  background: #16161a;
  box-sizing: border-box;
  gap: 8px;
  @media (max-width: 280px) {
    padding: 8px;
    min-height: 242px;
  }
  @media (min-width: 560px) {
    padding: 16px;
    min-height: 282px;
  }
  @media (min-width: $vp-1024) {
    padding: 24px;
  }
}
.componentStxmapCard {
  padding: 8px;
  gap: 16px;
  .contentWrapper {
    padding: 0 16px;
    gap: 8px;
    flex: 1;
    justify-content: space-between;
  }
  .buttonWrapper {
    padding: 0 16px 16px;
  }
}
.stxmapImage {
  align-self: stretch;
  & > img {
    width: 100%;
    margin: 0 auto;
  }
}
.stxmapSkeleton {
  position: relative;
  align-self: stretch;
  height: 200px;
  .stxmapSkeletonBg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .stxmapSkeletonRow {
      width: 100%;
      height: 3px;
      background-color: #16161a;
    }
  }
  .stxmapSkeletonRows {
    flex-direction: row;
    .stxmapSkeletonRow {
      width: 3px;
      height: 100%;
      background-color: #16161a;
    }
  }
}
.stxmapSkeletonBig {
  width: 300px;
  height: 300px;
}
.componentPending {
  background: linear-gradient(
    135deg,
    #242427 0%,
    #2e2e31 32.29%,
    #252529 51.56%,
    #1d1d20 100%
  );
  position: relative;
  &:hover {
    cursor: pointer;
    .externalLink {
      opacity: 1;
    }
  }
}
.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}
.componentSmallCard {
  min-height: 186px;
  .contentWrapper {
    gap: 8px;
  }
}
.headWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
.priceWrapper {
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 6px;
  @media (max-width: 280px) {
    font-size: 12px;
  }
  @media (min-width: 350px) {
    font-size: 12px;
  }
  @media (min-width: 530px) {
    font-size: 14px;
  }
  @media (min-width: $vp-1280) {
    font-size: 16px;
  }
}
.price {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  line-height: 19px;
  @media (max-width: 280px) {
    font-size: 13px;
  }
  @media (min-width: 350px) {
    font-size: 13px;
  }
  @media (min-width: 530px) {
    font-size: 14px;
  }
  @media (min-width: $vp-1280) {
    font-size: 18px;
  }
}
.title {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  // min-height: 33px;
  background: linear-gradient(94deg, #745aff 0%, #c361ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
  @media (max-width: 280px) {
    font-size: 16px;
  }
  @media (min-width: 350px) {
    font-size: 16px;
    word-break: break-word;
    text-align: center;
  }
  @media (min-width: 530px) {
    font-size: 18px;
  }
  @media (min-width: $vp-1280) {
    font-size: 24px;
  }
}
.titleStxsmap {
  font-size: 18px;
}
.externalLink {
  position: absolute;
  top: 24px;
  right: 24px;
  opacity: 0;
}
.titlePending {
  background: linear-gradient(94deg, #afafaf 0%, #afafaf 100%);
  background-clip: text;
}

.titleCoinSymbol {
  font-size: 16px;
  @media (min-width: 350px) {
    font-size: 14px;
  }
  @media (min-width: $vp-1280) {
    font-size: 16px;
  }
}

.hr {
  opacity: 0.05;
  background: #fff;
  width: 100%;
  height: 1px;
}
.dataWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.contentWrapperPending {
  gap: 11px;
  .dataWrapper {
    gap: 8px;
  }
}
.dataRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  gap: 8px;
}
.tokenData {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  flex: 1;
  @media (max-width: 560px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.value {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
  @media (max-width: 560px) {
    font-size: 13px;
  }
}
.ticker {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  padding: 6px 12px;
  border-radius: 32px;
  background: rgba(255, 255, 255, 0.05);
}
.token {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  @media (max-width: 560px) {
    font-size: 13px;
  }
}
.buttonWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}
.skeleton {
  overflow: hidden;
}
.connectButton {
  display: flex;
  height: 48px;
  border-radius: 16px;
  width: 100%;
}
.skeletonComponent {
  padding: 8px 8px 24px;
  border-radius: 16px;
  gap: 16px;
  flex-direction: column;
}
.skeletonContentWrapper {
  padding: 0px 16px;
  align-self: stretch;
  gap: 16px;
  display: flex;
  flex-direction: column;
}
.pd24 {
  padding: 24px;
}
.mt24 {
  margin-top: 24px;
}
.mt12 {
  margin-top: 12px;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.processed {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  border-radius: 4px;
  border: 1px solid #4528e3;
  background: linear-gradient(
    94deg,
    rgba(69, 40, 227, 0.3) 0%,
    rgba(165, 79, 218, 0.3) 100%
  );
  backdrop-filter: blur(3.5px);
  display: inline-flex;
  padding: 8px 10px;
  align-items: center;
  gap: 4px;
}
