@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.component {
  position: relative;
  background: #292A2F;
  width: 320px;
  min-height: 50px;
  overflow: hidden;
  border-radius: 20px;
  animation: fadeIn 0.2s ease;
}

.menuBg {
  position: fixed;
  z-index: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
}

.root {
  position: fixed;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 11;
  max-width: 430px;
}

.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 28px;
  padding: 20px;
}

.statusWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.dataNote {
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #ff9029;
  background: rgba(255, 255, 255, 0.05);
}
.noteText {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  line-height: normal;
  & > span {
    color: #fff;
  }
  text-align: center;
}
.close {
  background: #16161a;
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.title {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
}
.wrapper {
  justify-content: flex-start;
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  border-radius: 16px;
  background: #16161a;
}
.buttons, .buttons button {
  width: 100%;
}