.component {
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: #101013;
  padding: 0 24px;
  position: relative;
  &:focus-within {
    border: 1px solid rgba(143, 122, 255, 0.77);
  }
}
.componentContract {
  padding: 0 12px;
}

.wrapper {
  // position: relative;
  width: 100%;
}

.input {
  width: 100%;
  height: 56px;
  color: #fff;
  font-size: 16px;
  line-height: normal;
  background: #101013;
  border: 0;

  &:focus {
    outline: none;
  }
}

//.inputError {
//  width: calc(100% - 140px);
//}

.error {
  position: absolute;
  padding: 0 20px 0 0;
  right: 0;
  bottom: -39px;
  color: #ff5b5b;
  font-size: 14px;
  font-weight: 400;
  line-height: 56px;
}
.info {
  position: absolute;
  padding: 0 20px 0 0;
  right: 0;
  top: 2px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 56px;
  opacity: 0.5;
}
.infoContract {
  padding: 0 12px 0 0;
  font-size: 13px;
  top: 0px;
}
.maxBtn {
  color: #8f7aff;
  font-size: 16px;
  font-weight: 400;
  line-height: 56px;
  position: absolute;
  padding: 0 20px 0 0;
  right: 0;
  top: 0;
  cursor: pointer;
}

.inputContract {
  width: calc(100% - 120px);
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}
