@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
.root {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 430px;
  z-index: 3;
}
.component {
  position: relative;
  background: #292a2f;
  min-height: 50px;
  overflow-y: scroll;
  border-radius: 20px;
  animation: fadeIn 0.2s ease;
  max-height: calc(100vh - 50px);
}
.close {
  background: #16161a;
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.wrapper {
  justify-content: flex-start;
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  border-radius: 16px;
  background: #16161a;
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.title {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
}
.networkFee {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.networkFeeLabel {
  color: white;
  font-size: 14px;
  font-weight: 500;
}
.networkFeeLabelValue {
  font-size: 16px;
  font-weight: 700;
  color: white;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.inputsWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  align-self: stretch;
}
.item {
  position: relative;
  width: 100%;
}
.button {
  width: 100%;
}
.menuBg {
  position: fixed;
  z-index: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
}
.radioRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  .radioLabel {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
  }
  .radioBoxes {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
.floorAlertWrap {
  margin-top: -12px;
  flex: 1;
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #ff9029;
  background: rgba(255, 255, 255, 0.05);
  .floorAlertLabel {
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
  }
}
.switchWrap {
  display: flex;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  gap: 8px;
  & > span {
    min-width: 22px;
    text-align: right;
  }
}
.warningWrapper {
  display: flex;
  border-radius: 8px;
  border: 1px solid #ff9029;
  background: rgba(255, 255, 255, 0.05);
}
.warningContent {
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin: auto;
  padding: 8px;
  & > span {
    color: #fff;
  }
}
