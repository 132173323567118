@import "../../styles/variables.scss";

.component {
  border-top: 1px solid #1c1c1f;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-self: stretch;
  max-width: 891px;
  margin-top: 0;
}

.tabsWrapper {
  display: flex;
  justify-content: flex-start;
  gap: 24px;
  padding: 10px 0 0;
  align-items: center;

  @media (max-width: $vp-767) {
    gap: 14px;
  }
}

.tabButton {
  position: relative;
  color: #fff;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;

  &:hover {
    color: #8f7aff;
  }
}

.tabButtonActive {
  color: #8f7aff;

  &::before {
    content: "";
    width: 100%;
    height: 2px;
    background-color: #8f7aff;
    position: absolute;
    top: -2px;
  }
}

.tabContentWrapper {
  padding: 0;
}
