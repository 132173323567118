@import "../../styles/variables.scss";

.component {
  width: 100%;
  margin-top: 32px;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-self: stretch;
}
.headerWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-self: stretch;
}
.tabsWrapper {
  display: flex;
  justify-content: flex-start;
  gap: 24px;
  align-items: center;
  align-self: stretch;
  @media (max-width: $vp-768) {
    overflow-y: scroll;
  }
}
.tabsMyWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
}
.tabButton {
  position: relative;
  color: #fff;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    color: #8f7aff;
  }
  @media (max-width: $vp-375) {
    font-size: 14px;
  }
}
.tabButtonActive {
  color: #8f7aff;

  &::before {
    content: "";
    width: 100%;
    height: 2px;
    background-color: #8f7aff;
    position: absolute;
    top: -2px;
  }
}
.tabButtonDisabled {
  opacity: 0.5;
}
.searchWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  @media (max-width: 820px) {
    flex-wrap: wrap;
  }
  @media (max-width: $vp-1024) {
    row-gap: 16px;
    // justify-content: flex-end;
  }
}
.createOrderWrap {
  @media (max-width: 1186px) {
    width: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  @media (max-width: 820px) {
    width: 100%;
  }
}

.searchTickerInput {
  width: unset;
}
.dropdownWrapper {
  display: flex;
  align-items: center;
  display: flex;
  gap: 8px;
  // flex-shrink: 0;
}
.orderList {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  @media (min-width: 350px) {
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
  @media (min-width: 650px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
  }
  @media (min-width: 1142px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 32px;
  }
}
.orderList5 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  @media (min-width: 350px) {
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
  @media (min-width: 650px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
  }
  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 28px;
  }
  @media (min-width: 1260px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 32px;
  }
}
.orderListSkeleton {
  @media (max-width: 440px) {
    grid-template-columns: 1fr;
    gap: 16px;
  }
}
.paginatorWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.page {
  color: #fff;
  text-align: center;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  width: 47px;
  height: 47px;
  padding: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  background: #16161a;
}
.currentPageBg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 47px;
  height: 47px;
  background: linear-gradient(
    94deg,
    rgba(69, 40, 227, 1) 0%,
    rgba(165, 79, 218, 1) 100%
  );
  border-radius: 16px;
}
.currentPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 16px;
  border: 0px solid #4528e3;
  margin: 1px;
  // background: rgba(0, 0, 0, 0.1);
  background: linear-gradient(
    94deg,
    rgba(69, 40, 227, 0.25) 0%,
    rgba(165, 79, 218, 0.25) 100%
  );
}
.currentPageBlackBg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  background: #000;
  border-radius: 16px;
}
.ordersWrapper {
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  // align-items: center;
  gap: 40px;
  align-self: stretch;
}
.searchTickerWrapper {
  flex: 1;
  min-width: 316px;
  @media (max-width: $vp-425) {
    min-width: unset;
  }
  @media (max-width: $vp-820) {
    width: 100%;
    flex-basis: 100%;
  }
}
.button {
  @media (max-width: $vp-768) {
    width: 100%;
  }
}
.connectButtonWrapper {
  margin-top: 8px;
  display: flex;
  justify-content: center;
}
.dropdown {
  position: relative;
  min-width: 236px;
  padding: 14px 18px;
  @media (max-width: $vp-1100) {
    min-width: 204px;
    padding: 14px 8px;
  }
  @media (max-width: $vp-1024) {
    min-width: 200px;
    padding: 14px 8px;
  }
  @media (max-width: 840px) {
    min-width: 150px;
    padding: 14px 8px;
  }
}
.searchWrapper4Btn {
  display: flex;
  flex-wrap: wrap;

  .button {
    @media (max-width: 986px) {
      width: 100%;
    }
  }
  .searchTickerInputWrapper {
    @media (max-width: 986px) {
      max-width: unset;
    }
  }
  .dropdown {
    @media (max-width: $vp-1100) {
      min-width: 236px;
      padding: 14px 18px;
    }
    @media (max-width: 600px) {
      min-width: unset;
    }
    @media (max-width: 500px) {
      padding: 14px 8px;
    }
  }
  & > :first-child {
    @media (max-width: 986px) {
      width: 49%;
      flex: unset;
      order: 3;
    }
    @media (max-width: 680px) {
      width: 100%;
    }
  }
  & > :nth-child(2) {
    @media (max-width: 986px) {
      width: 49%;
      order: 1;
    }
    @media (max-width: 435px) {
      width: 100%;
    }
  }
  & > :nth-child(3) {
    @media (max-width: 986px) {
      width: 49%;
      order: 2;
    }
    @media (max-width: 435px) {
      width: 100%;
    }
  }
  & > :last-child {
    @media (max-width: 986px) {
      width: 49%;
      // flex-basis: 100%;
      order: 4;
    }
    @media (max-width: 680px) {
      width: 100%;
    }
  }
}
.listedAlert {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #ff5b5b;
  background: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.switchButtonGradientNew {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 48px;
  background: #ffffff0d;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  transition: 0.5s ease all;
  height: 40px;
  text-decoration: none;
  cursor: pointer;
  @media (max-width: $vp-1024) {
    font-size: 13px;
    width: 110px;
  }
  & > span {
    margin: 1px;
    background: #101013;
    font-size: 14px;
    line-height: 38px;
    width: 100%;
    text-align: center;
    border-radius: 48px;
    padding: 0px 16px;
    &:hover {
      background: linear-gradient(#161328 0%, #1f1727 100%);
    }
    @media (max-width: $vp-375) {
      font-size: 13px;
    }
    @media (max-width: $vp-320) {
      font-size: 12px;
    }
  }
}

.switchButtonGradientActive {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 48px;
  background: linear-gradient(
    94deg,
    rgba(69, 40, 227, 1) 0%,
    rgba(165, 79, 218, 1) 100%
  );
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  transition: 0.5s ease all;
  text-decoration: none;
  @media (max-width: $vp-1024) {
    font-size: 13px;
    width: 110px;
  }
  & > span {
    margin: 1px;
    width: 100%;
    text-align: center;
    border-radius: 48px;
    background: linear-gradient(#161328 0%, #1f1727 100%);
    &:hover {
      opacity: 0.825;
    }
    @media (max-width: $vp-375) {
      font-size: 13px;
    }
    @media (max-width: $vp-320) {
      font-size: 12px;
    }
  }
}
