.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  background: #374a4f;

  color: white;
  visibility: hidden; 
  opacity: 0;
  width: 226px;

  width: 226px;
  padding: 16px;

  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(31, 29, 43, 0.8);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.45);
  backdrop-filter: blur(12px);
  transition: opacity 1s;
}

.wrapperSelected {
  visibility: visible;
  opacity: 1;
}

.dataRow {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  color: #ffffff;
  span:first-child {
    color: rgba(255, 255, 255, 0.8);
  }
}
.popoverRoot {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
