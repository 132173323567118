@import "../../styles/variables.scss";

.component {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 316px;
  position: relative;
  @media (max-width: 820px) {
    max-width: unset;
  }
}
.input {
  display: flex;
  // width: 100%;
  height: 48px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: #101013;
  color: #fff;
  font-size: 16px;
  line-height: normal;
  padding: 0 24px 0 44px;
  z-index: 2;
  &:focus {
    outline: none;
    border: 1px solid rgba(143, 122, 255, 0.77);
  }
}
.error {
  position: absolute;
  padding: 0 10px 0 0;
  right: 0;
  top: 0;
  color: #ff5b5b;
  font-size: 12px;
  font-weight: 400;
  line-height: 50px;
}
.inputError {
  padding-right: 85px;
}
.icon {
  position: absolute;
  padding: 14px;
  left: 0;
  opacity: 0.5;
  z-index: 3;
}
.dropdownWrapper {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  gap: 8px;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 320px;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: #1f1d2b;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.2);
  }
}
.menuBg {
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.tickerItem {
  display: flex;
  align-self: stretch;
  padding: 12px;
  align-items: center;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
  gap: 8px;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }
}
.tickerId {
  color: rgba(255, 255, 255, 0.3);
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
}
.tickerTitleWrapper {
  display: flex;
  flex-direction: column;
  gap: 2px;
  flex: 1;
}
.tickerTitle {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}
.tickerFloorPrice {
  color: rgba(255, 255, 255, 0.6);
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  & > span {
    color: #8f7aff;
    font-size: 13px;
    font-weight: 700;
  }
}
.outerWrapper {
  overflow: hidden;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  // max-width: 320px; //check
  max-width: 100%;
  background: #1f1d2b;
  position: absolute;
  z-index: 3;
  top: 55px;
  left: 0;
  width: 100%;
}
.clear {
  position: absolute;
  right: 0;
}
