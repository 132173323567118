@import "../../../styles/variables.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-self: stretch;
  padding-bottom: 32px;
}
.inputWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
  row-gap: 24px;
  @media (max-width: $vp-767) {
    display: flex;
    flex-direction: column;
  }
}
.item {
  position: relative;
}
.buttonWrapper {
  display: flex;
  justify-content: flex-end;
}
.button {
  min-width: 183px;
}
.error {
  color: red;
}
.tabs {
  color: white;
  display: flex;
  gap: 8px;
}
.tabItem {
  border-radius: 48px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  padding: 14px 16px;
  &:hover {
    cursor: pointer;
  }
}
.tabItemActive {
  border: 1px solid #4528e3;
  background: linear-gradient(
    94deg,
    rgba(69, 40, 227, 0.1) 0%,
    rgba(165, 79, 218, 0.1) 100%
  );
}
.feeAlert {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  opacity: 0.8;
  align-self: flex-end;
}
