@import "../../styles/variables.scss";

.component {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: $vp-767) {
    margin-top: 18px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.descriptionWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.title {
  color: #fff;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  margin: 0;
  @media (max-width: $vp-767) {
    font-size: 24px;
    line-height: 32px;
  }
}
.caption {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  opacity: 0.6;
  @media (max-width: $vp-767) {
    font-size: 13px;
    line-height: 18px;
  }
}
.image {
  // height: 86px;
  max-height: 86px;
}
