@import "../../../styles/variables.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  max-width: 891px;
  margin-bottom: 32px;
}
.title {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
}
.contentWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 32px;
  row-gap: 24px;
  @media (max-width: $vp-767) {
    grid-template-columns: 1fr;
    justify-items: left;
    margin-bottom: 32px;
  }
}
.contentWrapperSingle {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
  row-gap: 24px;
  @media (max-width: $vp-767) {
    grid-template-columns: 1fr;
    justify-items: left;
    margin-bottom: 32px;
    & > div {
      width: 100%;
    }
  }
}
.txRender {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;
  @media (max-width: $vp-1024) {
    flex-direction: column;
  }
}
.blockDetails {
  background: rgba(22, 22, 26, 1);
  padding: 32px;
  width: 540px;
  border-radius: 16px;
  @media (max-width: $vp-1024) {
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
  }
}
.blockRow {
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
  &:first-child {
    padding-top: 0;
  }
  &:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
}
.blockLeft {
  color: rgba(255, 255, 255, 0.8);
}
.blockRight {
  display: flex;
  align-items: center;
  gap: 5px;
  color: rgba(255, 255, 255, 1);
  font-weight: 700;
}
.item {
  display: flex;
  align-items: center;
}
.itemLabel {
  color: #8f7aff;
  font-weight: 700;
  font-size: 20px;
  margin-left: 12px;
}
.iconWrapper {
  display: flex;
  height: 48px;
  width: 48px;
  border-radius: 48px;
  padding: 12px;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.renderPlaceholder {
  width: 300px;
  height: 300px;
}
.row {
  display: flex;
  color: white;
  opacity: 1;

  span {
    opacity: 0.5;
    margin-right: 5px;
  }
}
.itemInfo {
  margin-top: 10px;
}
.itemAddress {
  margin-right: 5px;
}
.renderContainer {
  //position: relative;
}
.itemDomain {
  display: flex;
  flex-direction: row;
  gap: 16px;

  .itemInfo {
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
