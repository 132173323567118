.component {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-self: stretch;
}

.tabsWrapper {
  display: flex;
  justify-content: flex-start;
  gap: 24px;
  padding: 10px 0 0;
  align-items: center;
}

.tabButton {
  position: relative;
  color: #fff;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;

  &:hover {
    color: #8f7aff;
  }
}

.tabButtonActive {
  color: #8f7aff;

  &::before {
    content: "";
    width: 100%;
    height: 2px;
    background-color: #8f7aff;
    position: absolute;
    top: -2px;
  }
}
.empty {
  color: #ff5b5b;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.loadingWrapper {
  display: flex;
  justify-content: center;
}
.loading {
  width: 32px;
}
