@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.component {
  position: relative;
  background: #292A2F;
  width: 320px;
  min-height: 50px;
  overflow: hidden;
  border-radius: 20px;
  animation: fadeIn 0.2s ease;
}

.root {
  position: fixed;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 11;
}

.close {
  margin-left: auto;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.10);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 28px;
  padding: 20px;
}

.statusWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
