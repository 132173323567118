@import "../../styles/variables.scss";
.component {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  max-width: 891px;
  position: relative;
}

.input {
  display: flex;
  width: 100%;
  height: 56px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: #101013;
  color: #fff;
  font-size: 16px;
  line-height: normal;
  text-align: center;
  &:focus {
    outline: none;
    border: 1px solid rgba(143, 122, 255, 0.77);
  }
}

.icon {
  position: absolute;
  padding: 18px;
  left: 0;
  @media (max-width: 500px) {
    display: none;
  }
}
