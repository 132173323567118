@import "../../styles/variables.scss";

.component {
  position: relative;
}
.selectedWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: #101013;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  display: flex;
  width: 280px;
  height: 48px;
  padding: 0px 16px;
  flex-shrink: 0;
  box-sizing: border-box;
}
.caption {
  display: flex;
  align-items: center;
  gap: 8px;
}
.label {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  line-height: 19px;
}
.title {
  color: #fff;
  font-size: 14px;
  line-height: 19px;
  @media (max-width: 535px) {
    font-size: 13px;
  }
}
.menuBg {
  position: fixed;
  z-index: 3;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.menuItems {
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #1f1d2b;
  position: absolute;
  z-index: 3;
  top: 55px;
  right: 0;
  display: flex;
  width: 280px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  box-sizing: border-box;
}
.inputs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.menuItem {
  display: flex;
  padding: 15px 16px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }
  @media (max-width: 535px) {
    padding: 15px 8px;
  }
}
.arrow {
  transition: all 0.5s ease;
  transform: rotate(-180deg);
}
.arrowOpened {
  transform: rotate(0deg);
}
.inputs {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-self: stretch;
  .input {
    box-sizing: border-box;
    width: 100%;
  }
}
.buttons {
  display: flex;
  height: 48px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  .button {
    display: flex;
    width: 100%;
    padding: 9px 24px;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 48px;
  }
}
.error {
  margin-top: -12px;
  color: #ff5b5b;
  font-size: 9px;
}
