@import "../../../styles/variables.scss";

.wrapper {
  display: flex;
  gap: 32px;
  max-width: 891px;
  @media (max-width: $vp-767) {
    display: flex;
    flex-direction: column;
  }
}
.nameWrapper {
  display: flex;
  min-width: 199px;
  min-height: 177px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  border-radius: 16px;
  background: #16161a;
}
.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.name {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  margin: 0;
}
.description {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  opacity: 0.5;
}
.contentWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  flex: 1;
  min-height: 177px;
  align-items: center;
  border-radius: 16px;
  background: #16161a;
  @media (max-width: $vp-767) {
    grid-template-columns: 1fr;
    justify-items: center;
    margin-bottom: 32px;
  }
}
.itemWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #222225;
  padding: 4px 0;
  &:last-child {
    border-right: 0;
  }
  @media (max-width: $vp-767) {
    padding: 32px 8px;
    border-right: 0;
    border-bottom: 1px solid #222225;
  }
}
.value {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  margin: 0;
}
.specification {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  opacity: 0.5;
}
