@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
.root {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 430px;
  z-index: 10;
}
.component {
  position: relative;
  background: #292a2f;
  min-height: 50px;
  overflow: hidden;
  border-radius: 20px;
  animation: fadeIn 0.2s ease;
}
.close {
  background: #16161a;
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.wrapper {
  justify-content: flex-start;
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  border-radius: 16px;
  background: #16161a;
}
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  margin-top: 8px;
}
.title {
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin: 0;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.info {
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  & > span {
    color: #fff;
    font-weight: 500;
  }
}
.txWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: center;
  cursor: pointer;
}
.txLink {
  color: #8f7aff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
.noteWrap {
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
  margin-top: 12px;
}
.noteText {
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  & > span {
    color: #fff;
    font-size: 13px;
    line-height: normal;
  }
}
.button {
  width: 100%;
  margin-top: 20px;
}
.menuBg {
  position: fixed;
  z-index: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
}
