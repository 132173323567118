@import "../../styles/variables.scss";

.component {
  background: #101013;
  width: 100%;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  align-items: center;
  padding: 24px 0 0;
  gap: 8px;

  @media (max-width: $vp-767) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 36px;
  }
  @media (max-width: $vp-1024) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.switchWrapper {
  display: flex;
  justify-content: center;
  gap: 8px;
  border-radius: 56px;
  align-items: center;

  @media (max-width: $vp-767) {
    width: 100%;
    margin-left: auto;
    order: 3;
  }
}

.logoWrapper {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.txtLogo {
  color: #fff;
  font-size: 16px;
  font-weight: 800;
  line-height: 22px;
  margin: 0;

  & > span {
    background: linear-gradient(180deg, #ff8d0f 0%, #fb5a1f 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.switchButtonGradient {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 48px;
  background: linear-gradient(
    94deg,
    rgba(69, 40, 227, 1) 0%,
    rgba(165, 79, 218, 1) 100%
  );
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  transition: 0.5s ease all;
  flex: 1;
  @media (max-width: $vp-1024) {
    font-size: 14px;
  }
  @media (max-width: $vp-375) {
    font-size: 13px;
  }
}
.switchButton {
  padding: 13px 0px;
  width: 100%;
  min-width: 116px;
  height: 100%;
  border-radius: 48px;

  // background: #1f1d2b;
  background: #101013;
  margin: 0px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  cursor: pointer;
  display: flex;
  justify-content: center;
  // transition: background 0.9s ease;
  color: white;
  text-decoration: none;

  @media (max-width: $vp-1024) {
    min-width: 100px;
  }
  @media (max-width: $vp-767) {
    padding: 14px 0;
  }
  @media (max-width: $vp-375) {
    min-width: unset;
  }

  &:hover {
    // background: rgba(37, 31, 62, 0.9);
    // background: #282637;
    background: linear-gradient(
      94deg,
      rgba(69, 40, 227, 0.1) 0%,
      rgba(165, 79, 218, 0.1) 100%
    );
    background: linear-gradient(#161328 0%, #1f1727 100%);
  }
}
.switchButtonActive {
  margin: 1px;
  border: 1px solid rgba(255, 255, 255, 0);
  background: rgba(37, 31, 62, 1);
  // background: #282637;
}
.buttonsWrapper {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: $vp-1024) {
    gap: 8px;
  }
}

.protocolButton {
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(31, 29, 43, 1);
  display: inline-flex;
  padding: 15px 10px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  transition: 0.5s ease all;
  cursor: pointer;
  white-space: nowrap;
  @media (max-width: $vp-767) {
    display: none;
  }
  @media (max-width: $vp-820) {
    display: none;
  }
  @media (min-width: $vp-1024) {
    min-width: 130px;
  }
  @media (min-width: $vp-1440) {
    min-width: 140px;
  }

  &:hover {
    background: #282637;
    // outline: 1px solid rgba(255, 255, 255, 0.1);
  }
}
.switchItemDisabled {
  opacity: 0.5;
  cursor: auto;
}
.switchButtonGradientActive {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 48px;
  background: linear-gradient(
    94deg,
    rgba(69, 40, 227, 1) 0%,
    rgba(165, 79, 218, 1) 100%
  );
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  transition: 0.5s ease all;
  flex: 1;
  height: 48px;
  width: 118px;
  text-decoration: none;
  @media (max-width: $vp-1024) {
    font-size: 13px;
    width: 110px;
  }
  & > span {
    margin: 1px;
    font-size: 14px;
    line-height: 46px;
    width: 100%;
    text-align: center;
    border-radius: 48px;
    background: linear-gradient(#161328 0%, #1f1727 100%);
    &:hover {
      opacity: 0.825;
    }
    @media (max-width: $vp-375) {
      font-size: 13px;
    }
    @media (max-width: $vp-320) {
      font-size: 12px;
    }
  }
}
.switchButtonGradientNew {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 48px;
  background: #ffffff0d;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  transition: 0.5s ease all;
  flex: 1;
  height: 48px;
  width: 118px;
  text-decoration: none;
  @media (max-width: $vp-1024) {
    font-size: 13px;
    width: 110px;
  }
  & > span {
    margin: 1px;
    background: #101013;
    font-size: 14px;
    line-height: 46px;
    width: 100%;
    text-align: center;
    border-radius: 48px;
    &:hover {
      background: linear-gradient(#161328 0%, #1f1727 100%);
    }
    @media (max-width: $vp-375) {
      font-size: 13px;
    }
    @media (max-width: $vp-320) {
      font-size: 12px;
    }
  }
}
